import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
// const baseStyle = defineStyle({
//   lineHeight: '1.2',
//   rounded: 'none',
//   fontWeight: 'normal',
//   textTransform: 'uppercase',
//   transitionProperty: 'common',
//   transitionDuration: 'normal',
//   _focusVisible: {
//     boxShadow: 'outline'
//   },
//   _disabled: {
//     opacity: 0.4,
//     cursor: 'not-allowed',
//     boxShadow: 'none'
//   },
//   _hover: {
//     _disabled: {
//       bg: 'initial'
//     }
//   }
// })
//
// const sizes = {
//   lg: defineStyle({
//     h: '16',
//     minW: '12',
//     fontSize: 'lg',
//     px: '10'
//   }),
//   md: defineStyle({
//     h: '14',
//     minW: '10',
//     fontSize: 'md',
//     px: '8'
//   }),
//   sm: defineStyle({
//     h: '12',
//     minW: '8',
//     fontSize: 'sm',
//     px: '6'
//   }),
//   xs: defineStyle({
//     h: '10',
//     minW: '6',
//     fontSize: 'xs',
//     px: '4'
//   })
// }

type AccessibleColor = {
  bg?: string
  color?: string
  hoverBg?: string
  activeBg?: string
}

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600'
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600'
  }
}

const variantSolid = defineStyle((props) => {
  const { colorScheme: c } = props

  if (c === 'gray') {
    const bg = mode(`gray.100`, `gray.600`)(props)

    return {
      bg,
      color: mode(`gray.800`, `gray.200`)(props),
      _hover: {
        bg: mode(`gray.200`, `gray.700`)(props),
        _disabled: {
          bg
        }
      },
      _active: { bg: mode(`gray.300`, `gray.800`)(props) }
    }
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`
  } = accessibleColorMap[c] ?? {}

  const background = bg

  return {
    bg: background,
    color: mode(color, color)(props),
    _hover: {
      bg: mode(hoverBg, hoverBg)(props),
      _disabled: {
        bg: background
      }
    },
    _active: { bg: mode(activeBg, activeBg)(props) }
  }
})

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    rounded: 'md',
    WebkitTapHighlightColor: 'transparent'
  },
  defaultProps: {
    colorScheme: 'primary'
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      fontWeight: 'normal'
    }
  },
  variants: {
    iconButton: () => ({
      rounded: 'full',
      color: 'primary',
      shadow: 'none',
      bg: 'transparent',
      WebkitTapHighlightColor: 'transparent'
    }),

    ghost: ({ colorScheme }) => ({
      shadow: 'none',
      textDecoration: 'underline',
      _hover: {
        bg: 'transparent'
      },
      _active: { color: `${colorScheme}.500`, bg: 'transparent' }
    }),

    link: () => ({
      textDecoration: 'underline'
    }),

    solid: variantSolid
    // solid: ({ colorScheme, theme }) => ({
    //   // bg: 'primary',
    //   // _active: { bg: `${colorScheme}.500` },
    //   // _hover: {
    //   //   bg: theme.colors.background.primaryGradHover,
    //   //   _disabled: {
    //   //     bg: theme.colors.background.primaryGrad
    //   //   }
    //   // }
    // }),
    //
    // outline: ({ theme }) => ({
    //   // bg: theme.colors.background.lightGrad,
    //   // _active: { bg: theme.colors.background.lightGrad },
    //   // _hover: { bg: theme.colors.background.lightGrad }
    // })
  }
})
