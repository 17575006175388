import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
  'wrapper',
  'bulletsWrapper',
  'bullet',
  'reviews'
])

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props

  return {
    bullet: {
      bg: `${c}.500`,
      boxShadow: `inset 0 0 0 1px var(--chakra-colors-${c}-500)`
    }
  }
})

const sizes = {
  sm: definePartsStyle({
    wrapper: {
      gap: 1
    },
    bulletsWrapper: {
      gap: 0.5
    },
    bullet: {
      h: '10px',
      w: '10px'
    },
    reviews: {
      fontSize: 'xs'
    }
  }),
  md: definePartsStyle({
    wrapper: {
      gap: 2
    },
    bulletsWrapper: {
      gap: 1
    },
    bullet: {
      h: '15px',
      w: '15px'
    },
    reviews: {
      fontSize: 'sm'
    }
  }),
  lg: definePartsStyle({
    wrapper: {
      gap: 5
    },
    bulletsWrapper: {
      gap: 1.5
    },
    bullet: {
      h: '20px',
      w: '20px'
    },
    reviews: {
      fontSize: 'md'
    }
  })
}

export const ratingTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
    colorScheme: 'primary'
  }
})
