import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { colors, semanticTokens } from './colors'
import { styleComponents } from './components'
import { fonts } from '#/src/styles/fonts'

const generateSpaces = ({
  from,
  to,
  stepValue,
  initialValue,
  unit = 'rem'
}: {
  from: number
  to: number
  stepValue: number
  initialValue: number
  unit?: string
}) => {
  let currentKey = from
  let currentValue = initialValue
  const res: Record<string, string> = {}

  while (currentKey <= to) {
    res[String(currentKey)] = `${currentValue}${unit}`
    currentValue += stepValue
    currentKey += 1
  }

  return res
}

export const customTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'secondary'
  }),
  {
    config: {
      initialColorMode: 'system',
      useSystemColorMode: true
    },
    fonts,
    styles: {
      global: {
        html: {
          h: '100%',
          w: '100%',
          overflow: 'auto',
          fontSize: {
            // base: '87.5%',
            // md: '100%'
          }
        },
        body: {
          h: '100%',
          w: '100%',
          touchAction: 'pan-y pan-x'
        },
        ul: {
          margin: 0
        }
      }
    },
    colors,
    semanticTokens,
    space: generateSpaces({
      from: 10,
      to: 30,
      initialValue: 2.5,
      stepValue: 0.25
    }),
    lineHeights: {
      double: 1.9
    },
    components: styleComponents,
    breakpoints: {
      xs: '30em',
      sm: '48em',
      md: '64em',
      lg: '80em',
      xl: '96em'
    },
    shadows: {
      aroundMd: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
      aroundLg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -2px rgba(0, 0, 0, 0.05)'
    }
  }
)

// console.log('customTheme', customTheme)
