import { headingComponentStyle } from './heading'
import { buttonTheme } from './button'
import { ratingTheme } from './rating'
import { counterScheme } from './counter'
import { formLabelTheme } from './formLabelTheme'
import { inputTheme } from './inputTheme'
import { badgeTheme } from './badgeTheme'
import datePicker from './datePicker'

export const styleComponents = {
  Heading: headingComponentStyle,
  Button: buttonTheme,
  Rating: ratingTheme,
  Counter: counterScheme,
  FormLabel: formLabelTheme,
  Input: inputTheme,
  Badge: badgeTheme,
  DatePicker: datePicker
}
