import { IconBaseProps } from 'react-icons/lib/cjs/iconBase'

import { createMultiStyleConfigHelpers, getCSSVar } from '@chakra-ui/styled-system'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
  'wrapper',
  'header',
  'headerMonthAndYear',
  'headerControl',
  'headerControlIcon'
])

const variantGourmate = definePartsStyle((props) => {
  const { colorScheme, theme } = props

  return {
    headerControl: {
      colorScheme: 'gray'
    },
    wrapper: {
      '.react-datepicker': {
        boxShadow: theme.shadows.popover,
        fontFamily: theme.fonts.body,
        color: 'text.default',
        border: `1px solid ${getCSSVar(theme, 'colors', 'chakra-border-color')}`,
        background: 'background.default'
      },
      '.react-datepicker__day': {
        fontWeight: 'bold'
      },
      '.react-datepicker__day--outside-month': {
        fontWeight: 'initial'
      },
      '.react-datepicker__header': {
        // border: 'none'
        background: 'background.surface',
        color: 'text.default'
      },
      '.react-datepicker-time__header': {
        fontWeight: 'initial',
        color: 'text.default'
      },
      '.react-datepicker__time-container .react-datepicker__time': {
        background: 'background.default'
      },
      '.react-datepicker__day, .react-datepicker__day-name': {
        color: 'text.default'
      },
      [`.react-datepicker__day:hover, 
            .react-datepicker__month-text:hover, 
            .react-datepicker__quarter-text:hover, 
            .react-datepicker__year-text:hover`]: {
        background: 'background.surface'
      },
      [`.react-datepicker__day--keyboard-selected, 
            .react-datepicker__month-text--keyboard-selected, 
            .react-datepicker__quarter-text--keyboard-selected, 
            .react-datepicker__year-text--keyboard-selected`]: {
        background: mode('info.300', 'info.700')(props)
      },
      [`.react-datepicker__day--keyboard-selected:hover, 
            .react-datepicker__month-text--keyboard-selected:hover, 
            .react-datepicker__quarter-text--keyboard-selected:hover, 
            .react-datepicker__year-text--keyboard-selected:hover`]: {
        background: mode('info.400', 'info.800')(props)
      },
      [`.react-datepicker__day--selected,
            .react-datepicker__day--in-selecting-range,
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--selected,
            .react-datepicker__month-text--in-selecting-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--selected,
            .react-datepicker__quarter-text--in-selecting-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--selected,
            .react-datepicker__year-text--in-selecting-range,
            .react-datepicker__year-text--in-range`]: {
        backgroundColor: `${colorScheme}.500`
      },
      [`.react-datepicker__day--selected:hover,
            .react-datepicker__day--in-selecting-range:hover,
            .react-datepicker__day--in-range:hover,
            .react-datepicker__month-text--selected:hover,
            .react-datepicker__month-text--in-selecting-range:hover,
            .react-datepicker__month-text--in-range:hover,
            .react-datepicker__quarter-text--selected:hover,
            .react-datepicker__quarter-text--in-selecting-range:hover,
            .react-datepicker__quarter-text--in-range:hover,
            .react-datepicker__year-text--selected:hover,
            .react-datepicker__year-text--in-selecting-range:hover,
            .react-datepicker__year-text--in-range:hover`]: {
        backgroundColor: `${colorScheme}.500`
      },
      [`.react-datepicker__time-container .react-datepicker__time 
            .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover`]:
        {
          backgroundColor: mode(`gray.200`, `gray.600`)(props)
        },
      [`.react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box
            ul.react-datepicker__time-list
            li.react-datepicker__time-list-item--selected`]: {
        backgroundColor: `${colorScheme}.500`
      },
      [`.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
            ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover`]: {
        backgroundColor: `${colorScheme}.500`
      },
      [`.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, 
            .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled`]: {
        opacity: 0.5
      },
      [`.react-datepicker__day--disabled:hover,
            .react-datepicker__month-text--disabled:hover,
            .react-datepicker__quarter-text--disabled:hover,
            .react-datepicker__year-text--disabled:hover`]: {
        backgroundColor: 'transparent'
      }
    }
  }
})

const variants = {
  gourmate: variantGourmate
}

const sizeMd = definePartsStyle(({ theme }) => {
  return {
    header: {
      fontSize: 'sm'
    },
    headerMonthAndYear: {
      marginInline: 3
    },
    headerControl: {
      size: 'sm'
    },
    headerControlIcon: {
      size: 18
    } as IconBaseProps,
    wrapper: {
      '.react-datepicker': {
        borderRadius: 'md'
      },
      '.react-datepicker__day, .react-datepicker__day-name': {
        width: '2.7em'
      },
      '.react-datepicker__header': {
        padding: 2
      },
      '.react-datepicker__header--time': {
        py: `calc(${theme.space[3]} + 2px)`,
        px: '2'
      },
      '.react-datepicker-time__header': {
        fontSize: 'sm'
      },
      '.react-datepicker__month': {
        py: 1,
        px: 2,
        margin: 0
      }
    }
  }
})

const sizes = {
  md: sizeMd
}

const datePickerStyle = defineMultiStyleConfig({
  variants,
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'gourmate',
    colorScheme: 'primary'
  }
})

export default datePickerStyle
