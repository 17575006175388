export const primaryColorScheme = {
  900: '#095d3e',
  800: '#0b744e',
  700: '#0e8b5d',
  600: '#10a26d',
  500: '#15d18c',
  400: '#17e89b',
  300: '#45edaf',
  200: '#74f1c3',
  100: '#a2f6d7',
  50: '#d1faeb'
}

export const secondaryColorScheme = {
  900: '#6e2912',
  800: '#9a3919',
  700: '#c54920',
  600: '#de5d33',
  500: '#e27450',
  400: '#e68665',
  300: '#ea977b',
  200: '#eda891',
  100: '#f1baa7',
  50: '#f8dcd3'
}

export const infoColorScheme = {
  900: '#0d22bf',
  800: '#586af3',
  700: '#8895f7',
  600: '#b5bdfa',
  500: '#cfd4fc',
  400: '#d4d9fc',
  300: '#dde2ff',
  200: '#e2e7ff',
  100: '#e4e8fd',
  50: '#eaeeff'
}

export const semanticTokens = {
  colors: {
    'chakra-body-text': { _light: '#2D3748', _dark: 'whiteAlpha.900' },
    'chakra-body-bg': { _light: 'white', _dark: '#000' },
    'chakra-border-color': { _light: 'gray.200', _dark: 'whiteAlpha.600' },
    'chakra-inverse-text': { _light: 'white', _dark: 'gray.800' },
    'chakra-subtle-bg': { _light: 'gray.100', _dark: 'gray.700' },
    'chakra-subtle-text': { _light: 'gray.600', _dark: 'gray.400' },
    'chakra-placeholder-color': { _light: 'gray.500', _dark: 'whiteAlpha.600' },
    primary: {
      default: 'primary.600'
    },
    secondary: {
      default: 'secondary.600'
    },
    info: {
      default: 'info.600'
    },
    text: {
      default: {
        default: '#2D3748',
        _dark: 'whiteAlpha.900'
      },
      invert: '#fff',
      secondary: {
        default: '#969696',
        _dark: '#b9b9b9'
      }
    },
    background: {
      default: {
        _dark: '#000',
        default: '#fff'
      },
      surface: {
        _dark: '#212121',
        default: '#f6f4f1'
      }
    }
  }
}

export const colors = {
  primary: primaryColorScheme,
  secondary: secondaryColorScheme,
  info: infoColorScheme,
  background: {
    primaryGrad:
      'linear-gradient(180deg, rgba(21,209,140,1) 0%, rgba(18,191,128,1) 50%, rgba(20,189,127,1) 100%)',
    primaryGradHover:
      'linear-gradient(0deg, rgba(21,209,140,1) 0%, rgba(18,191,128,1) 50%, rgba(20,189,127,1) 100%)',
    lightGrad:
      'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(252,252,252,1) 50%, rgba(249,249,249,1) 100%)'
  },
  status: {
    error: 'red'
  }
}
