'use client'

import React, { PropsWithChildren } from 'react'

import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react'
import { UIGlobalsContextProvider } from 'ui/lib/ui-globals'
import { defaultImage } from '#/src/images'

type BaseAppProvidersProps = PropsWithChildren<{
  colorModeManager?: ChakraProviderProps['colorModeManager']
  theme: Record<string, any>
}>

export default function BaseAppProviders({
  children,
  colorModeManager,
  theme
}: BaseAppProvidersProps) {
  return (
    <CacheProvider>
      <ChakraProvider colorModeManager={colorModeManager} resetCSS theme={theme}>
        <UIGlobalsContextProvider
          providerProps={{
            defaultImgSrc: defaultImage
          }}>
          {children}
        </UIGlobalsContextProvider>
      </ChakraProvider>
    </CacheProvider>
  )
}
