import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
  'wrapper',
  'button',
  'text'
])

const sizes = {
  xs: definePartsStyle({
    wrapper: {
      gap: 2
    },
    text: {
      fontSize: 'xs'
    }
  }),
  sm: definePartsStyle({
    wrapper: {
      gap: 3
    },
    text: {
      fontSize: 'sm'
    }
  }),
  md: definePartsStyle({
    wrapper: {
      gap: 4
    },
    text: {
      fontSize: 'md'
    }
  }),
  lg: definePartsStyle({
    wrapper: {
      gap: 5
    },
    text: {
      fontSize: 'lg'
    }
  })
}

export const counterScheme = defineMultiStyleConfig({
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'primary'
  }
})
