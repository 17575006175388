import { Nunito } from 'next/font/google'

const fontNunito = Nunito({
  weight: '400',
  subsets: ['latin' as 'latin' | 'latin-ext']
})

export const fonts = {
  // default fonts
  heading: fontNunito.style.fontFamily,
  body: fontNunito.style.fontFamily
}
