import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const main = defineStyle({
  fontSize: '2xl',
  w: 'full'
})

const secondary = defineStyle({
  fontSize: 'lg',
  textAlign: 'left',
  w: 'full'
})

export const headingComponentStyle = defineStyleConfig({
  baseStyle: {},
  variants: {
    main,
    secondary
  },
  sizes: {
    main: defineStyle({
      fontSize: '2xl'
    }),
    secondary: defineStyle({
      fontSize: 'lg'
    }),
    '5xl': defineStyle({
      fontSize: ['4xl', '4xl', '5xl'],
      lineHeight: 1.3
    }),
    '4xl': defineStyle({
      fontSize: ['3xl', '3xl', '4xl'],
      lineHeight: 1
    }),
    '3xl': defineStyle({
      fontSize: ['2xl', '2xl', '3xl'],
      lineHeight: 1
    }),
    '2xl': defineStyle({
      fontSize: ['xl', 'xl', '2xl'],
      lineHeight: [1.2, null, 1]
    }),
    xl: defineStyle({
      fontSize: ['lg', 'lg', 'xl'],
      lineHeight: [1.33, null, 1.2]
    }),
    lg: defineStyle({
      fontSize: ['md', 'md', 'lg'],
      lineHeight: [1.33, null, 1.2]
    }),
    md: defineStyle({
      fontSize: 'md',
      lineHeight: 1.2
    }),
    sm: defineStyle({
      fontSize: 'sm',
      lineHeight: 1.2
    }),
    xs: defineStyle({
      fontSize: 'xs',
      lineHeight: 1.2
    })
  }
})
